<template>
  <article class="workReport" v-loading="loading">
    <RouterNav name="查看年级作业报告"></RouterNav>
    <header class="header">
      <div class="left">
        <el-autocomplete
          class="input"
          v-model="searchName"
          :fetch-suggestions="querySearch"
          placeholder="请输入学校名称搜索"
          :trigger-on-focus="false"></el-autocomplete>
        <el-select class="col" v-model="year" placeholder="年度">
          <el-option v-for="(item, index) in yearList" :key="index" :label="item" :value="item"></el-option>
        </el-select>
        <el-select class="col" v-model="gradeId" placeholder="年级" @change="gradeChange">
          <el-option v-for="(item, index) in gradeList" :key="index" :label="item.gradeName" :value="item.gradeTypeId"></el-option>
        </el-select>
        <el-select class="date" v-model="dateType" placeholder="时间">
          <el-option v-for="(item, index) in dateTypeList" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div :class="['time', dateType ? 'hidden' : '']">
          <el-date-picker
            class="picker"
            v-model="startTime"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :clearable="false"></el-date-picker>
          <div class="text">至</div>
          <el-date-picker
            class="picker"
            v-model="endTime"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :clearable="false"></el-date-picker>
        </div>
      </div>
      <el-button type="primary" @click="search">查询</el-button>
    </header>

    <div class="empty" v-if="!arrangeSum && arrangeSum !== 0">
      <div>
        <img src="../../../assets/default.png" alt="default">
        <div>请选择年级和年度进行查阅</div>
      </div>
    </div>
    <div class="workReport-container" v-show="arrangeSum || arrangeSum === 0">
      <section class="section">
        <div class="title">
          <div class="left">
            <div class="line"></div>
            <div>数据概览</div>
            <div class="icon"></div>
            <div class="tips">在此期间，<span class="col-329cf3">{{gradeName}}</span>共布置了<span class="col-329cf3">{{arrangeSum}}</span>份作业。整体完成情况如下：</div>
          </div>
        </div>
        <div class="content" ref="content">
          <div class="wrapper">
            <Gauge ref="activeGauge"></Gauge>
<!--            <div class="relative">-->
<!--              <div class="bar-tips">*点击蓝色柱状图可查看对应人数</div>-->
<!--              <barForReport ref="activeBar"></barForReport>-->
<!--            </div>-->
          </div>
          <div class="wrapper">
            <Gauge ref="scoreGauge"></Gauge>
<!--            <div class="relative">-->
<!--              <div class="bar-tips">*点击蓝色柱状图可查看对应人数</div>-->
<!--              <barForReport ref="scoreBar"></barForReport>-->
<!--            </div>-->
          </div>
        </div>
      </section>

      <section class="section">
        <div class="title">
          <div class="left">
            <div class="line"></div>
            <div>班级报告</div>
          </div>
          <div class="right" @click="classReportChange">
            <div class="hidden">{{classReportShow ? '点击收起' : '点击查看更多'}}</div>
            <div :class="['btn', classReportShow ? 'rotate-180' : '']"></div>
          </div>
        </div>
        <div v-show="classReportShow">
          <div class="sub">
            <el-tooltip class="item" effect="dark" content="优异班级：得分率≥80%的班级" placement="top-start">
              <div class="sub-title">
                <span>表现优异班级</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="excellentListShowAll ? excellentList : excellentList.slice(0, 10)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="班级"
                align="center"
              ></el-table-column>
              <el-table-column
                label="完成度"
                sortable
                sort-by="finish"
                align="center"
              >
                <template slot-scope="props">
                  <div>{{props.row.finish}}%</div>
                </template>
              </el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="classReport(scope.row.clazzId, scope.row.name)">查看班级报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('excellentListShowAll')">
            <div>{{excellentListShowAll ? '收起' : '查看更多'}}</div>
            <img :class="[excellentListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>

          <div class="sub sub-3">
            <el-tooltip class="item" effect="dark" content="需重点关注班级：得分率<60%的班级" placement="top-start">
              <div class="sub-title">
                <span>需重点关注班级</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="monitorListShowAll ? monitorList : monitorList.slice(0, 5)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="班级"
                align="center"
              ></el-table-column>
              <el-table-column
                label="完成度"
                sortable
                sort-by="finish"
                align="center"
              >
                <template slot-scope="props">
                  <div>{{props.row.finish}}%</div>
                </template>
              </el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="classReport(scope.row.clazzId, scope.row.name)">查看班级报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('monitorListShowAll')">
            <div>{{monitorListShowAll ? '收起' : '查看更多'}}</div>
            <img :class="[monitorListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>

          <div class="sub sub-2">
            <el-tooltip class="item" effect="dark" content="默认是根据“得分率”的值由高到低排名" placement="top-start">
              <div class="sub-title">
                <span>全部班级列表</span><img class="mark" src="../../../assets/mark-2.png" alt="?">
              </div>
            </el-tooltip>
          </div>
          <div class="table">
            <el-table
              :data="classListShowAll ? classList : classList.slice(0, 5)">
              <el-table-column
                prop="rank"
                label="排名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="班级"
                align="center"
              ></el-table-column>
              <el-table-column
                label="完成度"
                sortable
                sort-by="finish"
                align="center"
              >
                <template slot-scope="props">
                  <div>{{props.row.finish}}%</div>
                </template>
              </el-table-column>
              <el-table-column
                label="得分率"
                sortable
                sort-by="scoreRate"
                align="center"
              >
                <template slot-scope="props">
                  <div :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">{{props.row.scoreRate}}%</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <div class="link" @click="classReport(scope.row.clazzId, scope.row.name)">查看班级报告</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="more-btn" @click="listShowAllChange('classListShowAll')">
            <div>{{classListShowAll ? '收起' : '查看更多'}}</div>
            <img :class="[classListShowAll ? 'rotate-180' : '']" src="../../../assets/z-1.png" alt="more">
          </div>
        </div>
      </section>

    </div>

  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav'
import Gauge from '../components/Gauge'
export default {
  name: 'gradeWorkReport',
  components: {
    RouterNav,
    Gauge,
  },
  data () {
    return {
      loading: false,
      searchSchoolList: [],
      searchName: '',
      searchId: '',
      yearList: [],
      year: '',
      gradeList: [],
      gradeId: '',
      gradeName: '',
      dateTypeList: [{
        id: 1,
        name: '近一周'
      }, {
        id: 2,
        name: '近一个月'
      }, {
        id: 0,
        name: '自定义'
      }],
      dateType: 2,
      startTime: '',
      endTime: '',
      arrangeSum: '',
      barWidth: '',
      finishGroupObj: null,
      scoringGroupObj: null,
      classReportShow: false,
      excellentList: [],
      excellentListShowAll: false,
      monitorList: [],
      monitorListShowAll: false,
      classList: [],
      classListShowAll: false,
      homeWorkListShow: false,
      homeWorkList: [],
      homeWorkListShowAll: false
    }
  },
  watch: {
    searchName (val) {
      const { searchSchoolList } = this
      if (searchSchoolList.length === 0) return
      for (let i = 0, len = searchSchoolList.length; i < len; i += 1) {
        if (val === searchSchoolList[i].schoolName) {
          this.searchId = searchSchoolList[i].schoolId
          break
        }
      }
    },
    searchId (val) {
      this.year = ''
      this.gradeId = ''
      this.getGradeBySchoolId(val)
    }
  },
  mounted () {
    document.getElementById('app').scrollTop = 0
    this.$store.commit('includeAdd', 'gradeWorkReport')
    this.getSchoolList()
  },
  methods: {
    getSchoolList () {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getSchoolList
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            const schoolId = sessionStorage.getItem('schoolId')
            for (let i = 0, len = data.length; i < len; i += 1) {
              data[i].value = data[i].schoolName
              if (schoolId === data[i].schoolId) {
                this.searchId = schoolId
                this.searchName = data[i].schoolName
              }
            }
            this.searchSchoolList = data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    initGaugeCharts (target, data) {
      const option = {
        series: [{
          type: 'gauge',
          splitNumber: 1,
          progress: {
            show: true,
            width: 14,
            itemStyle: {
              color: '#3875FD'
            },
            roundCap: true
          },
          axisLine: {
            lineStyle: {
              width: 14,
              color: [
                [1, '#E5E5E5']
              ]
            },
            roundCap: true
          },
          axisTick: {
            show: false
          },
          pointer: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            distance: -35,
            fontSize: 10
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            fontSize: 17,
            offsetCenter: [0, 0],
            formatter: function (value) {
              return value + '%\n{unit|' + data.name + '}'
            },
            rich: {
              unit: {
                fontSize: 12,
                color: '#999'
              }
            },
            color: '#333333'
          },
          data: [{
            value: data.value
          }]
        }]
      }
      target.initCharts({
        dataParam: option
      })
    },
    initBarCharts (target, data) {
      const { offsetWidth } = this.$refs.content
      const barWidth = (offsetWidth / 2) - 30
      const echarts = require('echarts')
      const option = {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: 'white'
          },
          formatter: function (params) {
            const { dataIndex } = params
            const res = `<p>${data.name}：${params.name}</p><p>人数：${data.studentSumList[dataIndex]}人</p>`
            return res
          }
        },
        title: {
          show: true,
          text: `${data.name}分布统计`,
          subtext: '人数占比(%)',
          textStyle: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 'normal'
          },
          subtextStyle: {
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 'normal'
          }
        },
        xAxis: {
          axisLine: {show: false},
          axisTick: {show: false},
          show: true,
          textStyle: {
            color: '#666'
          },
          emphasis: {
            color: '#fff'
          },
          data: data.xNameList
        },
        series: [{
          name: '人数',
          type: 'bar',
          data: data.xValueList,
          barWidth: 60,
          itemStyle: {
            color: '#C0E0FB',
            label: {
              show: true,
              formatter: '{c}人',
              position: 'top',
              textStyle: {
                color: 'black',
                fontSize: 16
              }
            }
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#6DBBFC'}, // 柱图高亮渐变色
                  {offset: 1, color: '#309AF2'} // 柱图高亮渐变色
                ]
              )
            }
          }
        }]
      }
      target.initCharts({
        dataParam: option,
        width: barWidth
      })
    },
    querySearch (queryString, cb) {
      const { searchSchoolList } = this
      const list = searchSchoolList.filter(this.schoolFilter(queryString))
      cb(list)
    },
    schoolFilter (name) {
      return (school) => {
        return (school.schoolName.indexOf(name) !== -1)
      }
    },
    getGradeBySchoolId (val) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getGradeBySchoolId,
        data: {
          schoolId: val
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            const searchYearList = []
            let searchGradeList = []
            for (let i = 0, len = data.length; i < len; i += 1) {
              if (!searchYearList.includes(data[i].year)) {
                searchYearList.push(data[i].year)
              }
              if (data[i].gradeTypeId) {
                searchGradeList.push(data[i])
              }
            }
            searchGradeList = this.common.uniqueForObj(searchGradeList, 'gradeName')
            searchGradeList.sort((a, b) => {
              return a.gradeTypeId - b.gradeTypeId
            })
            this.gradeList = searchGradeList
            searchYearList.sort()
            this.yearList = searchYearList
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    search () {
      const { searchId, gradeId, year, dateType } = this
      if (!searchId) {
        this.$alert('请选择学校', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!gradeId) {
        this.$alert('请选择年级', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!year) {
        this.$alert('请选择年度', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      this.classReportShow = false
      this.excellentList = []
      this.excellentListShowAll = false
      this.monitorList = []
      this.monitorListShowAll = false
      this.classList = []
      this.classListShowAll = false
      const dataParams = {
        schoolId: searchId,
        gradeId,
        year,
        dateType
      }
      if (!dateType) {
        dataParams.startTime = this.startTime
        dataParams.endTime = this.endTime
      }
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getDataViewSuper,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParams
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            this.arrangeSum = data.arrangeSum
            const obj = {
              name: '完成度',
              value: data.finish
            }
            this.initGaugeCharts(this.$refs.activeGauge, obj)
            const obj2 = {
              name: '得分率',
              value: data.scoreRate
            }
            this.initGaugeCharts(this.$refs.scoreGauge, obj2)
            // const { finishGroup, scoringGroup } = data
            // const finishGroupObj = {
            //   name: '完成度',
            //   xNameList: [],
            //   xValueList: [],
            //   classList: [],
            //   studentSumList: []
            // }
            // for (let i = 0, len = finishGroup.length; i < len; i += 1) {
            //   finishGroupObj.xNameList.push(finishGroup[i].columns)
            //   finishGroupObj.xValueList.push(finishGroup[i].proportion)
            //   finishGroupObj.studentSumList.push(finishGroup[i].studentSum)
            // }
            // this.finishGroupObj = finishGroupObj
            // const scoringGroupObj = {
            //   name: '得分率',
            //   xNameList: [],
            //   xValueList: [],
            //   classList: [],
            //   studentSumList: []
            // }
            // for (let i = 0, len = scoringGroup.length; i < len; i += 1) {
            //   scoringGroupObj.xNameList.push(scoringGroup[i].columns)
            //   scoringGroupObj.xValueList.push(scoringGroup[i].proportion)
            //   scoringGroupObj.studentSumList.push(scoringGroup[i].studentSum)
            // }
            // this.scoringGroupObj = scoringGroupObj
            // this.$nextTick(() => {
            //   this.initBarCharts(this.$refs.activeBar, finishGroupObj)
            //   this.initBarCharts(this.$refs.scoreBar, scoringGroupObj)
            // })
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    getClassReport () {
      this.loading = true
      const { searchId, gradeId, year, dateType } = this
      const dataParams = {
        schoolId: searchId,
        gradeId,
        year,
        dateType
      }
      if (!dateType) {
        dataParams.startTime = this.startTime
        dataParams.endTime = this.endTime
      }
      this.$axios({
        method: 'post',
        url: this.$urls.getClassReport,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParams
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            this.excellentList = data.excellentList
            this.monitorList = data.monitorList
            this.classList = data.classList
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    classReportChange () {
      this.classReportShow = !this.classReportShow
      if (this.classReportShow && this.classList.length === 0) {
        this.getClassReport()
      }
    },
    listShowAllChange (attr) {
      this[attr] = !this[attr]
    },
    gradeChange (val) {
      const { gradeList } = this
      for (let i = 0, len = gradeList.length; i < len; i += 1) {
        if (gradeList[i].gradeTypeId === val) {
          this.gradeName = gradeList[i].gradeName
          break
        }
      }
    },
    classReport (classId, className) {
      const { dateType } = this
      const params = {
        name: '查看班级作业报告',
        classId,
        className,
        dateType
      }
      if (!dateType) {
        params.startTime = this.startTime
        params.endTime = this.endTime
      }
      this.$store.commit('includeDel', 'classWorkReport')
      this.$router.push({
        name: 'classWorkReport',
        query: params
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .header {
    @include flex(space-between);
    background-color: #fff;
    height: 56px;
    padding: 0 28px;
    margin-bottom: 10px;
    font-size: 15px;
    .left {
      display: flex;
    }
    .input {
      width: 250px;
      margin-right: 10px;
    }
    .col {
      width: 90px;
      margin-right: 20px;
    }
    .date {
      width: 120px;
    }
    .picker {
      width: 150px;
    }
    .time {
      display: flex;
      align-items: center;
      .text {
        margin: 0 10px;
      }
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: calc(100vh - 194px);
    color: #999999;
    font-size: 14px;
    text-align: center;
    img {
      width: 90px;
      height: 103px;
      margin-bottom: 14px;
    }
  }
  .workReport-container {
    height: calc(100vh - 230px);
    overflow: auto;
  }
  .section {
    background-color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 66px;
      padding-right: 28px;
      @include font;
      font-weight: bold;
      &:hover {
        .hidden {
          visibility: visible;
        }
      }
      .left {
        display: flex;
        align-items: center;
      }
      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
      .icon {
        background-image: url("../../../assets/icon-2.png");
        background-size: 100% 100%;
        width: 10px;
        height: 10px;
        margin-left: 20px;
      }
      .tips {
        background-color: #EAF4FD;
        height: 34px;
        line-height: 34px;
        padding: 0 20px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: normal;
      }
      .right {
        display: flex;
        align-items: center;
        color: #309AF2;
        font-size: 14px;
      }
      .btn {
        background: #EAF4FD url("../../../assets/z-1.png") no-repeat center center;
        background-size: 11px 6px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;
        transition: all .3s;
      }
      .loading-btn {
        background: #EAF4FD url("../../../assets/loading.png") no-repeat center center;
        background-size: 16px 16px;
        animation: rotate .5s linear infinite;
      }
    }
    .content {
      display: flex;
      padding: 0 28px 20px;
      .wrapper {
        flex: 1;
        padding: 10px 0;
        border: 1px solid #E5E5E5;
        .gauge {
          width: 180px;
          height: 180px;
          margin: 0 auto;
        }
        .name {
          padding-left: 26px;
          margin-bottom: 12px;
          font-size: 14px;
          font-weight: bold;
        }
        .bar-tips {
          color: #999999;
          font-size: 10px;
          position: absolute;
          right: 30px;
          top: 21px;
        }
      }
    }
    .sub {
      border-top: 2px solid #1DC060;
      .sub-title {
        display: inline-block;
        background: linear-gradient(to right, #1DC060, #3EE199);
        height: 28px;
        line-height: 28px;
        margin-left: 28px;
        padding: 0 14px;
        border-radius: 0 0 8px 8px;
        color: #fff;
        font-size: 14px;
        .mark {
          width: 14px;
          height: 14px;
          margin-left: 6px;
          vertical-align: middle;
        }
      }
      .sub-title-default {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
      .sub-title-warn {
        background: linear-gradient(to right, #FF6F5E, #FF6F5E);
      }
    }
    .sub-2 {
      border-top: 2px solid #309AF2;
      .sub-title {
        background: linear-gradient(to right, #309AF2, #5ECAFA);
      }
    }
    .sub-3 {
      border-top: 2px solid #FF3C30;
      .sub-title {
        background: linear-gradient(to right, #FF3C30, #FF6F5E);
      }
    }
    .table {
      padding: 0 28px 20px;
      .link {
        color: #309AF2;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .more-btn {
      @include flex;
      background-color: #F6F6F6;
      width: 120px;
      height: 32px;
      margin: 0 auto 40px;
      border-radius: 20px;
      color: #309AF2;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 7px;
        height: 4px;
        margin-left: 4px;
        transition: all .3s;
      }
    }
  }
</style>
